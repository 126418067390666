.form_relative {
    position: relative;
    min-height: 378px;
    height: max-content;
    width: 80%;
    max-width: 378px;
    padding-bottom: 14px;
    border-radius: 25px;
}

.loading_form {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    height: 378px;
    width: 378px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 25px;
}

.form_content {
    margin-left: 32px;
    margin-right: 32px;
}

.form_title {
    padding-top: 16px;
    margin-top: 24px;
    font-family: Helvetica Neue, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 48px;
    text-align: left;
    color: white;
}

.form_group_name {
    margin-top: 20px;
}

.form_img_g {
    height: 24px;
    width: 24px;
}

.form_link {
    margin-top: 8px;
    font-family: Helvetica Neue, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
}

.form_description {
    margin-top: 18px;
    margin-right: 8px;
    margin-left: 8px;
    font-family: Helvetica Neue, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    color: #000000;
}

.form_recover_account {
    font-family: Helvetica Neue, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #999999;
}

.form_registration {
    margin-top: -8px;
    margin-bottom: 6px;
    font-family: Helvetica Neue, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
}

.success_button {
    background-color: #4C6ED7
}

.success_button:hover {
    background-color: #3c58ac
}

.form_change {
    color: #0d6efd;
    text-align: center;
    margin-top: 6px;
    font-size: 14px;
    cursor: pointer;
}

.invalid_feedback {
    height: auto;
    width: 100%;
    margin-top: 4px;
    font-size: 80%;
    color: #dc3545;
}

.invalid_feedback_sign_up {
    width: 100%;
    margin-top: 4px;
    font-size: 80%;
    color: #dc3545;
}
.main_container {
  color: #fff;
  background-image: url("../public/firmbeecom.jpg");
  background-position: top;
  background-size: cover;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.App {
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(76, 110, 215);
  background: linear-gradient(
          0deg,
          rgba(76, 110, 215, 1) 0%,
          rgba(76, 110, 215, 1) 50%,
          rgba(76, 110, 215, 0.6) 100%
  );
}

.container {
  width: 100%;
  margin: 15% auto;
}

.col-4 {
  width: 25%;
  float: left;
}

h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
}

@media (min-width: 768px) {
  .container {
    width: 1100px;
  }
  h1 {
    font-size: 58px;
  }
}

.wrapper {
  color: #444;
}

.modal_button {
  background-color: rgba(76, 110, 215, 1);
}

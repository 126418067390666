.sign_in_title {
    font-size: 48px;
    font-weight: bold;
    text-align: center;
}
.sign_in_subtitle {
    font-size: 36px;
    text-align: center;
}

.form_content {
    margin-left: 32px;
    margin-right: 32px;
}

.form_relative {
    position: relative;
    min-height: 378px;
    height: max-content;
    width: 80%;
    max-width: 378px;
    padding-bottom: 14px;
    border-radius: 25px;
}

.name_text {
    font-size: 18px;
    text-align: center;
}

.payment_title {
    font-size: 32px;
    text-align: center;
}

.success_button {
    background-color: #4C6ED7
}

.success_button:hover {
    background-color: #3c58ac
}

.invalid_feedback {
    height: auto;
    width: 100%;
    margin-top: 4px;
    font-size: 80%;
    color: #dc3545;
}